import React from "react";
import { TextField, ControlledRichTextEditor } from "saga-library/src";
import { useFormContext } from "saga-library/src/components/Form";
import { PractitionerSelect } from "../../../../components/PractitionersSelect";
import { getErrorMessage } from "./LetterTemplateFormValidation";
import { FormHelperTextWithWarning } from "saga-library/src/components/FormHelperTextWithWarning";
import { FieldError } from "react-hook-form";
import { FormRow } from "../../../../components/FormRow";
import { PermissionType } from "../../../../types/settings/Permission";
import { PermissionForm } from "../../../../components/PermissionForm";
import { useParams } from 'react-router-dom'
import { GET_FILE_DETAILS_FROM_URL } from '../../../../graphql-definitions'
import { useDocumentContext } from '../../../../providers/DocumentProvider'
import { useConfigurationContext } from '../../../../providers/Configuration'

interface LetterTemplateFormProps {
  onSubmit: (data: any) => void
  formName: string
}

export const LetterTemplateForm = ({
  onSubmit,
  formName,
}: LetterTemplateFormProps) => {
  const { formState: {errors} } = useFormContext()
  const { tenant_id } = useParams()
  const { uploadFiles, deleteFile, getFileTypeUriWithToken } = useDocumentContext()
  const { getConfigValue } = useConfigurationContext()
  const froalaKey = getConfigValue('FROALA_CLIENT_TOKEN')

  return (
    <PermissionForm
      name={formName}
      onSubmit={onSubmit}
      id={formName}
      requiredPermissionType={PermissionType.Chart}
      readOnlyOverride={true}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
      }}
    >
      <FormRow>
        <TextField
          name={'name'}
          label={'Template name'}
          fullWidth={true}
          dataTestId={"letterTemplateForm-name"}
        />
        <TextField
          name={'title'}
          label={'Title'}
          fullWidth={true}
          dataTestId={"letterTemplateForm-title"}
        />
        <PractitionerSelect
          label={'Practitioner'}
          name={'practitionerId'}
          dataTestId={"letterTemplateForm-practitioner-select"}/>
      </FormRow>
      <FormHelperTextWithWarning
        error={errors && errors.content as FieldError}
        helperText={getErrorMessage(errors, "content")}
        dataTestId={"letterTemplateForm-helper-text"}
      />
      <ControlledRichTextEditor
        froalaKey={froalaKey}
        tenantId={tenant_id}
        name={"content"}
        showTemplateToolbar={true}
        placeholderText={"??"}
        textEditorHeight={'500px'}
        loopPlaceholders={true}
        dataTestId={"letterTemplateForm-content"}
        getFileDetailsCall={GET_FILE_DETAILS_FROM_URL}
        uploadFiles={uploadFiles}
        deleteFile={deleteFile}
        getFileTypeUriWithToken={getFileTypeUriWithToken}
      />
    </PermissionForm>
  )
}